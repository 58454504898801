
import CURRENT_HOTEL_GRAPH_COLOR from '@/modules/common/constants/current-hotel-graph-color.constant';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import GraphLegendIcon from '@/modules/common/components/ui-kit/graph-legend-icon.vue';
import HotelsService, { HotelsServiceS } from '@/modules/hotels/hotels.service';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import FleetService, { FleetServiceS } from '@/modules/cars/modules/fleet/fleet.service';
import PROVIDER_COLORS from '@/modules/common/constants/providers.colors.constant';
import { DEFAULT_GRAPH_COLORS as GRAPH_COLORS } from '@/modules/common/constants/default-graph-colors.constant';

export interface IFleetLabel {
    name: string | null;
    color: string;
}

@Component({
    components: {
        GraphLegendIcon,
    },
})
export default class FleetGraphBrokersLabel extends Vue {
    @Inject(HotelsServiceS) private hotelsService!: HotelsService;
    @Inject(UserServiceS) private userService!: UserService;
    @Inject(FleetServiceS) private fleetService!: FleetService;

    @Prop({
        type: String,
        required: true,
    })
    provider!: string;

    get currentBroker(): IFleetLabel | null {
        const { currentCompany } = this.userService;

        return {
            name: currentCompany,
            color: CURRENT_HOTEL_GRAPH_COLOR,
        };
    }

    get brokers(): IFleetLabel[] | null {
        return this.fleetService.getCompaniesByProvider(this.provider).map((broker, index) => ({
            name: broker,
            color: PROVIDER_COLORS[broker] || GRAPH_COLORS.pop() || '#555555', // this.fleetService.getBrokersGraphColor(index),
        })).filter(item => item.name !== this.userService.currentCompany)
            .filter(item => this.fleetService.competitors.includes(item.name));
    }

    get getIconColor() {
        return this.userService.currentCompany ? PROVIDER_COLORS[this.userService.currentCompany] : '#555555';
    }
}
