
import CURRENT_HOTEL_GRAPH_COLOR from '@/modules/common/constants/current-hotel-graph-color.constant';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import type Day from '@/modules/common/types/day.type';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { ChartData, ChartOptions, ChartDataSets } from 'chart.js';
import { Inject } from 'inversify-props';
import FleetGraphTooltip from '@/modules/cars/modules/fleet/components/graph/fleet-graph-tooltip.vue';
import Demand from '@/modules/common/components/ui-kit/demand.vue';
import CustomGraph from '@/modules/common/components/ui-kit/custom-graph/graph.vue';
import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';
import LoaderWrapper from '@/modules/common/components/loader-wrapper.vue';
import FleetService, { FleetServiceS } from '@/modules/cars/modules/fleet/fleet.service';

@Component({
    components: {
        CustomGraph,
        Demand,
        LoaderWrapper,
        FleetGraphTooltip,
    },
})
export default class FleetGraphBrokers extends Vue {
    @Inject(UserServiceS) protected userService!: UserService;
    @Inject(DocumentFiltersServiceS) private documentFiltersService!: DocumentFiltersService;
    @Inject(FleetServiceS) private fleetService!: FleetService;

    @Prop({
        type: String,
        required: true,
    })
    provider!: string;

    onTooltipClick(day: Day) {
        this.$router.push({
            name: 'fleet-density.graph.day-popup-source',
            params: {
                day: String(day),
                source: String(this.provider),
            },
        });
    }

    get chartData(): ChartData {
        const datasets: ChartDataSets[] = [];

        if (!this.documentFiltersService.days) {
            return {
                labels: [],
                datasets: [],
            };
        }
        this.fleetService.getCompaniesByProvider(this.provider)
            .filter(item => this.fleetService.competitors.includes(item))
            .forEach((brokerId, index) => datasets.push(this.dataSetByDay(brokerId, index)));

        return {
            labels: this.documentFiltersService.days,
            datasets,
        };
    }

    dataSetByDay(broker: string, index: number):ChartDataSets {
        return {
            label: broker,
            data: this.fleetService.getCountByBroker(this.provider, broker),
            borderColor: this.borderColor(broker, index),
            borderWidth: index ? 3 : 2,
            lineTension: 0,
            borderJoinStyle: 'round',
        };
    }

    borderColor(brokerName: string, index: number) {
        return brokerName === this.userService.currentCompany ? CURRENT_HOTEL_GRAPH_COLOR : this.colorByHotel(index);
    }

    colorByHotel(brokerIndex: number) {
        return this.fleetService.getBrokersGraphColor(brokerIndex);
    }

    get options(): ChartOptions {
        return {
            maintainAspectRatio: false,
            elements: {
                line: {
                    backgroundColor: 'rgba(255, 255, 255, 0.1)',
                },
                point: {
                    backgroundColor: 'white',
                },
            },
            scales: {
                xAxes: [{
                    gridLines: {
                        display: true,
                        borderDash: [0, 1],
                        offsetGridLines: true,
                        color: '#ECF1F5',
                    },
                    ticks: {
                        callback(value) {
                            return value < 10 ? `0${value}` : value;
                        },
                    },
                }],
                yAxes: [{
                    scaleLabel: {
                        display: true,
                        labelString: '# Cars',
                    },
                    gridLines: {
                        display: true,
                        offsetGridLines: true,
                        borderDash: [0, 4],
                        color: '#ECF1F5',
                        zeroLineWidth: 0,
                    },
                    offset: true,
                    ticks: {
                        autoSkip: true,
                        callback(value) {
                            return value >= 10000 ? `${Number(value) / 1000}k` : value;
                        },
                    },
                }],
            },
            legend: {
                display: false,
            },
            plugins: {
                filler: {
                    propagate: true,
                },
            },
        };
    }
}
